:root {
  --white: #ffffff;
  --black: #000000;
  --gray: #f2f2f2;
  --light-gray: #e5e5e5;
  --very-light-gray: #f2f2f2;
  --dark-gray: #78829c;
  --blue: #1890ff;
  --dark-blue: #1771c5;
  --green: #27ae60;
}

/* Media Queries
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* MOBILE FIRST */
$mf_mobileM: 'only screen and (min-width: 23.4375em)'; // 375px
$mf_mobileL: 'only screen and (min-width: 26.5625em)'; // 425px
$mf_mobileXL: 'only screen and (min-width: 35em)'; // 560px
$mf_tablet: 'only screen and (min-width: 48em)'; // 768px
$mf_tabletExclude: 'only screen and (min-width: 48.0625em)'; // 769px
$mf_tabletL: 'only screen and (min-width: 62em)'; // 992px
$mf_laptop: 'only screen and (min-width: 64em)'; // 1024px
