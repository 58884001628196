/* NOTE
  html is set to 62.5% so that all the REM measurements are based on 1rem sizing.
  For example, 1.5rem = 15px
*/

html {
  font-size: 62.5%;
  height: auto;
  padding: env(safe-area-inset);
}

body {
  font-size: 1.4em;
  line-height: 1.2;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: normal;
  color: var(--black);
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: scrollbar;
  padding: 0;
  margin: 0;
}

a {
  color: var(--blue);
  transition: color 0.25s ease-in-out;

  &:hover,
  &:focus {
    color: var(--dark-blue);
  }
}
