@import '../../style/variables.scss';

.sidebar {
  position: fixed;
  top: 0;
  left: -30rem;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-between;
  background-color: var(--very-light-gray);
  width: 24.3rem;
  min-height: 100vh;
  padding: 1.8rem 1.5rem 3rem 0;
  margin-right: -1.5rem;
  transition: left 0.25s ease-in-out;

  .sidebar--is-active & {
    left: 0rem;
  }

  @media #{$mf_tablet} {
    position: relative;
    left: 0;
    width: 25.8rem;
  }

  &__logo {
    display: block;
    width: 100%;
    max-width: 11rem;
    margin: 0 auto;
  }

  &__nav {
    width: 100%;
    margin: 3rem 0 1.5rem;

    .nav {
      &__list {
        display: flex;
        flex-flow: column;
        list-style: none;
        width: 100%;
        padding: 0 1.5rem;
        margin: 0;

        .list {
          &__item {
            width: 100%;
            margin-bottom: 0.5rem;

            .item__link {
              display: flex;
              flex-flow: row nowrap;
              justify-content: flex-start;
              align-items: center;
              font-size: 1.6rem;
              font-weight: 500;
              background-color: transparent;
              border-radius: 0.7rem;
              color: var(--black);
              height: 5rem;
              width: 100%;
              padding: 0.5rem 1.7rem;
              transition: background 0.25s ease-in-out;

              &:hover,
              &:focus,
              &--active {
                background-color: var(--light-gray);
              }

              .link {
                &__img {
                  width: 2.4rem;
                  margin-right: 1.6rem;
                }
              }
            }
          }
        }
      }
    }
  }

  &__user {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;

    .user {
      &__img {
        border-radius: 50%;
        width: 100%;
        max-width: 8.4rem;
      }

      &__name {
        font-size: 1.6rem;
        font-weight: 500;
        margin: 2rem 0 1rem;
      }

      &__links {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          padding: 0;
          margin: 0;
        }

        .links {
          &__item {
            display: block;
            text-align: center;
            padding: 0.3rem 0;

            color: var(--blue);
            transition: color 0.25s ease-in-out;

            &:hover,
            &:focus {
              color: var(--dark-blue);
            }

            &--exit {
              &::after {
                content: '';
                display: inline-block;
                background: url('../../images/icon-exit.svg') 0 / 100% no-repeat;
                width: 1.3rem;
                height: 1rem;
                margin-left: 0.5rem;
              }
            }
          }
        }
      }

      &__progress-bar {
        position: relative;
        width: 11.4rem;
        height: 11.4rem;
        margin: 3rem auto 2.5rem auto;

        .progress-bar {
          &__bar {
            .ant-progress-text {
              display: none;
            }
          }
          &__inner {
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 1.2rem;
            color: rgba(0, 0, 0, 0.65);
            text-align: center;
            line-height: 1.2;

            .inner {
              &__value-wrapper {
                margin-top: 0.9rem;

                .value {
                  font-size: 13px;

                  &--current {
                    color: var(--green);
                  }

                  &--max {
                    color: var(--blue);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &__footer {
    .footer {
      &__copyright {
        text-align: center;
        font-size: 1.2rem;
        color: var(--dark-gray);
      }

      &__list {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0;

        .list {
          &__item {
            font-size: 1.2rem;
            color: var(--blue);

            &:nth-child(2) {
              &::before {
                content: '\02022';
                color: currentColor;
                padding: 0 0.3rem;
              }
            }
          }
        }
      }
    }
  }
}
