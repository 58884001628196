@import './style/variables.scss';
@import './style/base.scss';

@import '~antd/dist/antd.css';

.main-container {
  width: 100%;
  min-height: 100vh;

  @media #{$mf_tablet} {
    display: flex;
    flex-flow: row nowrap;
  }

  &__content {
    left: 0;
    background: var(--white);
    width: 100%;
    min-height: 100vh;
    transition: all 0.25s ease-in-out;

    @media #{$mf_tablet} {
      box-shadow: 0rem 0.4rem 2.1rem rgba(0, 0, 0, 0.25);
      border-radius: 1.5rem 0rem 0rem 1.5rem;
      width: calc(100% - 25.8rem);
      z-index: 2;
    }

    .content {
      &__header {
        padding: 0 1.5rem;

        .header {
          &__toggle-sidebar {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            font-size: 2rem;
            font-weight: 500;
            transition: color 0.25s ease-in-out;

            &:hover,
            &:focus {
              color: var(--blue);
            }

            @media #{$mf_tablet} {
              display: none;
            }

            .hamburger {
              &__button {
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                border: none;
                width: 4rem;
                height: 4rem;
                padding: 0;
                margin-right: 0.3rem;

                span {
                  position: relative;
                  color: var(--black);
                  background-color: currentColor;
                  height: 0.2rem;
                  width: 60%;
                  transition: all 0.25s ease-in-out;

                  &::after,
                  &::before {
                    content: '';
                    position: absolute;
                    background-color: currentColor;
                    left: 0;
                    height: 0.2rem;
                    width: 100%;
                    transition: all 0.2s ease-in-out;
                  }

                  &::after {
                    top: -0.8rem;
                  }

                  &::before {
                    top: 0.8rem;
                  }
                }

                &--active {
                  span {
                    background-color: transparent;

                    &::after {
                      transform: rotate(45deg);
                      top: -1px;
                    }

                    &::before {
                      transform: rotate(-45deg);
                      top: -1px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &__body {
        padding: 0 1.5rem;
      }
    }
  }

  &.sidebar--is-active {
    .main-container__content {
      position: fixed;
      left: 22.8rem;
      box-shadow: 0rem 0.4rem 2.1rem rgba(0, 0, 0, 0.25);
      border-radius: 1.5rem 0rem 0rem 1.5rem;

      @media #{$mf_tablet} {
        position: relative;
        left: 0;
        width: calc(100% - 24.3rem);
      }
    }
  }
}

.site-layout-background {
  background: #fff;
}

.ant-layout-header {
  background: transparent;
}

.button--styles-reset {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
